<template>
  <div class="dialog-container">
    <el-dialog
      :title="`${isEdit ? $t('edit') : $t('add')}`"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="50%"
    >
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="教练姓名" prop="coachName">
              <el-input
                clearable
                placeholder="最多输入20个字"
                v-model="form.coachName"
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="telephone">
              <el-input
                clearable
                v-model="form.telephone"
                :maxlength="15"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证件号码" prop="idCard">
              <el-input
                clearable
                v-model="form.idCard"
                :maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="授课项目" prop="type">
              <el-select v-model="form.type" style="width: 100%">
                <el-option
                  v-for="item in sportList"
                  :key="item.sportsProjectCode"
                  :label="item.sportsProjectName"
                  :value="item.sportsProjectCode"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="教练头像" prop="avatar">
              <el-upload
                class="upload"
                :show-file-list="false"
                :with-credentials="true"
                :action="apis.ImageUpload"
                :httpRequest="uploadHttpDefault"
                name="file"
                accept=".png,.jpg"
                :on-success="uploadSuccess"
                :on-error="uploadError"
              >
                <img
                  v-if="form.avatarUrl"
                  class="image-upload"
                  :src="form.avatarUrl"
                  alt=""
                  srcset=""
                  width="78"
                  height="78"
                />
                <img
                  v-else
                  class="image-upload"
                  src="@/assets/images/upload_image.png"
                  alt=""
                  srcset=""
                />
                <div class="upload-images-tip">
                  图片尺寸不小于60*60px，图片格式png/jpg
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="教练简介" prop="introduction">
              <el-input
                clearable
                type="textarea"
                :rows="4"
                placeholder="最多输入200字"
                v-model="form.introduction"
                maxlength="200"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{
          $t("sure")
        }}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{
          $t("cancel")
        }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import mixin from "@/mixins/dialogMixin";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      flag: false,
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
      roleData: [], // 角色树状的值
      apis,
      showPic: true,
      sportList: [],
    };
  },
  activated() {
    this.getSportList();
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "TOKEN"]),
  },
  methods: {
    getSportList() {
      this.$http
        .get(`${apis.getSportsNewProject}?id=${this.venueId}`)
        .then((res) => {
          if (res.data.code === 0) {
            this.sportList = res.data.rows;
          }
        });
    },
    uploadSuccess(res) {
      console.log(res);
      this.form.avatarUrl = res.data.url;
      this.form.avatar = res.data.path;
    },
    uploadError(res) {
      console.log(res);
    },
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.form = new AddDialogClass("form");
      console.log("我初始化了");
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.flag) {
            return;
          }
          this.flag = true;
          if (this.isEdit) {
            this.$http.put(apis.coachInfo, this.form).then((res) => {
              if (res.data.code === 0) {
                // 调用关闭方法，不要再写一次
                this.flag = false;
                this.closeDialog();
              } else {
                this.flag = false;
              }
            });
          } else {
            this.$http
              .post(apis.coachInfo, {
                ...this.form,
              })
              .then((res) => {
                if (res.data.code === 0) {
                  // 调用关闭方法，不要再写一次
                  this.flag = false;
                  this.closeDialog();
                } else {
                  this.flag = false;
                }
              });
          }
        }
      });
      console.log(`我执行了点确定的业务需求`);
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      this.$http.get(`${apis.coachInfo}/${data.id}`).then((res) => {
        if (res.data.code === 0) {
          this.form = { ...this.form, ...res.data.data };
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color: rgba(168, 168, 168, 1);
  font-size: 12px;
  margin-top: -15px;
}
.image-wrap {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
