/*
 * @Author: genwai
 * @Date: 2020-05-19 14:54:36
 * @LastEditTime: 2020-06-12 15:42:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\course\dialog\addDialogClass.js
 */
/* eslint-disable */

class searchFrom {
  validatePhone = (rule, value, callback) => {
    var phone=value.replace(/\s/g, "");//去除空格
    //校验手机号，号段主要有(不包括上网卡)：130~139、150~153，155~159，180~189、170~171、176~178。14号段为上网卡专属号段
    let regs = /^((13[0-9])|(17[0-1,6-8])|(15[^4,\\D])|(18[0-9]))\d{8}$/;
    if(!value){
      callback([new Error('请输入联系电话')]);
      return
    } 
    // else {
    // 手机号或座机号都可以
    if(!regs.test(phone) && !/^\d{3}-\d{8}$|^\d{4}-\d{7,8}$/.test(phone)) {
        callback([new Error('手机号或座机号不合法')]);
    } else {
        callback();
    }
    //  }
  }
  validateIdCard = (rule, value, callback) => {
    var phone=value.replace(/\s/g, "");//去除空格
    let regs = /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/;
    let res2 = /(^[EeKkGgDdSsPpHh]\d{8}$)|(^(([Ee][a-fA-F])|([DdSsPp][Ee])|([Kk][Jj])|([Mm][Aa])|(1[45]))\d{7}$)/;
    if(!value){
      callback([new Error('请输入证件号')]);
    } else {
        if(!regs.test(phone) || !!res2.test(phone)){
            callback([new Error('证件号输入不合法')]);
        } else {
            callback();
        }
   }
  }
  constructor(type) {
    if (type === 'form') {
      this.avatar = ''
      this.avatarUrl = ''
      this.coachName = ''
      this.idCard = ''
      this.telephone = ''
      this.type = ''
      this.introduction = ''
      this.files = []
    } else if (type === 'rule') {
      this.coachName = [
        {
          required: true,
          message: '请输入教练名称',
          trigger: 'blur',
        },
      ]
      this.idCard = [
        {
          required: true,
          validator: this.validateIdCard,
          trigger: 'blur',
        },
      ]
      this.telephone = [
        {
          required: true,
          validator: this.validatePhone,
          trigger: 'blur',
        },
      ]
      this.type = [
        {
          required: true,
          message: '请选择项目',
          trigger: 'blur',
        },
      ]
      this.introduction = [
        {
          required: true,
          message: '请输入教练简介',
          trigger: 'blur',
        },
      ]
      this.avatar = [
        {
          required: true,
          message: '请选择图片',
          trigger: 'change',
        },
      ]
    }
  }
}
export default searchFrom
